export const container = `
  justify-between
  p-[10px]
  backdrop-blur-[10px]
  bg-white/[10%]
  border-white/25
  border-[1px]
`

export const button = ({ hidden }) => {
  let css = `
    bg-amber-600
    hover:bg-amber-700
  `

  hidden && (css += 'invisible ')

  return css
}
