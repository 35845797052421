const dot = `
  rounded-full
  duration-100
`

const unselectedDot = `
  ${dot}
  h-[10px]
  w-[10px]
  cursor-pointer
  bg-black/50
  hover:bg-black/75
`

const selectedDot = `
  ${dot}
  h-[15px]
  w-[15px]
  bg-black
`

export default ({ step, currentStep }) => {
  const isSelected = step === currentStep
  return isSelected ? selectedDot : unselectedDot
}
