export const addServer = require('./addServer.png')
export const discord = require('./discord.png')
export const installModpack = require('./installModpack.png')
export const launcher = require('./launcher.png')
export const playModpack = require('./playModpack.png')
export const curseGreeting = require('./curseGreeting.png')
export const joinServer = require('./joinServer.png')
export const multiplayer = require('./multiplayer.png')
export const typeInServer = require('./typeInServer.png')
export const dirtTexture = require('./dirtTexture.png')
export const grassTexture = require('./grassTexture.png')
