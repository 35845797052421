export default `
  bg-white/[10%]
  py-[5px]
  px-[10px]
  mx-[5px]
  min-w-[185px]
  rounded-[3px]
  border-white/[25%]
  border-[1px]
  cursor-pointer
  select-none
  duration-100
  hover:bg-white/[30%]
  active:bg-white/[40%]
`
