export default `
  rounded-[25px]
  px-[25px]
  py-[5px]
  text-white
  uppercase
  tracking-[2px]
  bg-blue-400
  hover:bg-blue-500
  duration-100
  select-none
`
