import style from './Flex.css'
import { backgroundImage } from 'styles'

export default ({ background, column, center, css = '', ...props }) => (
  <div
    style={backgroundImage(background)}
    className={style({ column, center }) + css}
    {...props}
  />
)
