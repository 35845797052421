import { Link, Button } from 'components'
import { determineOS } from 'features/instructions/utils'
import { curseDownloads } from 'features/instructions/constants'

export default ({ ...props }) => {
  const os = determineOS()

  return (
    <Link href={curseDownloads[os]} {...props}>
      <Button>Download Curse</Button>
    </Link>
  )
}
