import { Flex, Link, Button } from 'components'
import { modPackLink } from 'features/instructions/constants'
import { InstructionImage } from 'features/instructions/components'

export default () => (
  <Flex column center>
    <h2 className="uppercase">2. Install the modpack</h2>
    <InstructionImage src="installModpack" />
    <Link href={modPackLink}>
      <Button>Download the modpack</Button>
    </Link>
  </Flex>
)
