import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { App } from 'pages'

export default () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
    </Routes>
  </BrowserRouter>
)
