import style from './Text.css'

export default ({ header, css = '', children, ...props }) => {
  const textProps = {
    className: style + css,
    ...props,
  }

  return header ? (
    <h1 {...textProps}>{children}</h1>
  ) : (
    <div {...textProps}>{children}</div>
  )
}
