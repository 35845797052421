import { useRecoilValue } from 'recoil'
import { stepAtom } from 'features/instructions/atoms'
import * as steps from 'features/instructions/pages'

export default () => {
  const currentStep = useRecoilValue(stepAtom)
  const Step = steps?.[currentStep] || steps?.Curse

  return <Step />
}
