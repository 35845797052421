import { Flex, Link, Button } from 'components'
import { discordServer } from 'features/instructions/constants'
import { InstructionImage } from 'features/instructions/components'

export default () => (
  <Flex column center>
    <h2 className="uppercase">5. Join the discord server</h2>
    <InstructionImage src="discord" />
    <Link href={discordServer}>
      <Button css="bg-purple-500 hover:bg-purple-600">Join Discord</Button>
    </Link>
  </Flex>
)
