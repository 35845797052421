import { Flex, Image } from 'components'
import {
  MinecraftContainer,
  GlassContainer,
  ProgressDots,
  Navigation,
  Step,
} from './components'

export default () => (
  <Flex column center css="min-h-screen">
    <Image src="logo" alt="logo" css="h-[300px] my-[25px]" />
    <MinecraftContainer css="mb-[25px]">
      <GlassContainer>
        <ProgressDots />
        <Step />
      </GlassContainer>
      <Navigation />
    </MinecraftContainer>
  </Flex>
)
