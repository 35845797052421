import * as images from 'features/instructions/assets'

export default ({ src, alt, css = '', ...props }) => (
  <img
    src={images?.[src] || src}
    alt={alt}
    className={'rounded-[5px] ' + css}
    {...props}
  />
)
