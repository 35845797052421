export const dirt = `
  rounded-[25px]
  overflow-hidden
  relative
  flex-col
  z-0
  w-[600px]
  shadow-xl
  text-white
`

export const grass = `
  w-full
  h-[50px]
  absolute
  z-[-1]
`
