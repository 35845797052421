import { Flex, Button } from 'components'
import { steps } from 'features/instructions/constants'
import { stepAtom } from 'features/instructions/atoms'
import { useRecoilState } from 'recoil'
import { scrollToTop } from 'utils'
import findIndex from 'lodash/findIndex'
import * as c from './Navigation.css'

export default () => {
  const [step, setStep] = useRecoilState(stepAtom)

  const currentStep = findIndex(steps, (e) => e === step)

  const onFirstStep = currentStep === 0
  const onLastStep = currentStep === steps.length - 1

  const nextStep = () => {
    const nextIndex = currentStep + 1
    const next = steps[nextIndex]
    scrollToTop()
    next && setStep(next)
  }

  const previousStep = () => {
    const previousIndex = currentStep - 1
    const previous = steps[previousIndex]
    scrollToTop()
    previous && setStep(previous)
  }

  return (
    <Flex css={c.container}>
      <Button css={c.button({ hidden: onFirstStep })} onClick={previousStep}>
        Previous
      </Button>
      <Button css={c.button({ hidden: onLastStep })} onClick={nextStep}>
        Next
      </Button>
    </Flex>
  )
}
