import { Flex } from 'components'
import * as c from './MinecraftContainer.css'
import { dirtTexture, grassTexture } from 'features/instructions/assets'

export default ({ css = '', children, ...props }) => {
  return (
    <Flex css={c.dirt + css} background={dirtTexture} {...props}>
      <Flex css={c.grass} background={grassTexture} />
      {children}
    </Flex>
  )
}
