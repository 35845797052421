export default `
  prose
  prose-slate
  m-[25px]
  mt-[50px]
  p-[25px]
  backdrop-blur-[10px]
  bg-white/[35%]
  rounded-[10px]
  border-white/25
  border-[1px]
`
