import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { Recoil, Routes } from './providers'
import 'styles/global.css'

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <Recoil>
      <Routes />
    </Recoil>
  </StrictMode>,
)
