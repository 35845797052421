import { Flex } from 'components'
import { useRecoilState } from 'recoil'
import { steps } from 'features/instructions/constants'
import { stepAtom } from 'features/instructions/atoms'
import style from './ProgressDots.css'

export default ({ css = '', ...props }) => {
  const [step, setStep] = useRecoilState(stepAtom)

  return (
    <Flex center css={'gap-[10px] h-[15px] ' + css} {...props}>
      {steps?.map((currentStep) => (
        <Flex
          key={currentStep}
          onMouseDown={() => setStep(currentStep)}
          css={style({ step, currentStep })}
        />
      ))}
    </Flex>
  )
}
