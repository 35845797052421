import { Flex } from 'components'
import {
  DownloadCurse,
  InstructionImage,
} from 'features/instructions/components'

export default () => (
  <Flex column center>
    <h2 className="uppercase">1. Download and install Curse</h2>
    <InstructionImage src="curseGreeting" />
    <DownloadCurse />
  </Flex>
)
