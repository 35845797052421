export default (link) => {
  const isExternal = (link) =>
    link?.includes('http://') || link?.includes('https://')
  const isProtocol = (link) =>
    link?.includes('mailto:') || link?.includes('tel:')

  if (!link) return 'None'
  if (isExternal(link)) return 'External'
  if (isProtocol(link)) return 'Protocol'
  return 'Internal'
}
