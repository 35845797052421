import { Flex } from 'components'
import { InstructionImage } from 'features/instructions/components'

export default () => (
  <Flex column center>
    <h2 className="uppercase">3. Start Minecraft</h2>
    <InstructionImage src="playModpack" />
    <p>Click "Play" on the modpack when complete</p>
    <InstructionImage src="multiplayer" />
    <p>Start minecraft and click "Multiplayer"</p>
  </Flex>
)
