import style from './Link.css'
import { Link as RouterLink } from 'react-router-dom'
import { determineLink } from 'utils'

const links = {
  Internal: ({ href, ...props }) => <RouterLink to={href} {...props} />,

  External: ({ children, href, ...props }) => (
    <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  ),

  Protocol: ({ children, href, ...props }) => (
    <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  ),

  None: ({ children }) => <>{children}</>,
}

export default ({ css = '', ...props }) => {
  const linkType = determineLink(props.href)
  const Link = links[linkType]

  return <Link className={style + css} {...props} />
}
