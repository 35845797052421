import { Flex, Text } from 'components'
import { CopyIp, InstructionImage } from 'features/instructions/components'

export default () => (
  <Flex column center>
    <h2 className="uppercase">4. Join the server</h2>
    <InstructionImage src="addServer" />
    <p>Click "Add Server"</p>
    <InstructionImage src="typeInServer" />
    <Flex center>
      <Text>Type in </Text>
      <CopyIp />
      <Text>to "Server Address"</Text>
    </Flex>
    <InstructionImage src="joinServer" />
    <p>Click "Join Server"</p>
  </Flex>
)
