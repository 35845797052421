import { useState } from 'react'
import { Flex, Text, Icon } from 'components'
import { serverIp } from 'features/instructions/constants'
import style from './CopyIp.css'

export default () => {
  const [copied, setCopied] = useState(false)
  const copyToClipboard = () => {
    setCopied(true)
    navigator.clipboard.writeText(serverIp)
    setTimeout(() => setCopied(false), 1500)
  }

  return (
    <Flex center css={style} onMouseDown={copyToClipboard}>
      <Text css="pr-[5px]">{copied ? 'copied!' : serverIp}</Text>
      {!copied && (
        <Icon src="copy" css="path:fill-stone-600 h-[14px] w-[14px]" />
      )}
    </Flex>
  )
}
